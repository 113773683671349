/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: #2c4e9c !important;
}

.prev-acc {
  color: #36abe0;
  ion-accordion {
    --border-width: 0;
    --border-color: transparent;
    --border-style: none;
  }
}

.netz-toast {
  &-success,
  &-warn {
    --color: red;

    &.success {
      &::part(container) {
        --background-color: green;
      }
    }

    &.warn::part(container) {
      --background-color: yellow;
    }
    // &.error {
    //   --background: var(--ion-color-danger);
    // }
  }
}

.netz-toast-error {
  --color: #ffffff;
  --background: #d24d57;
}
.netz-toast-success {
  --color: #ffffff;
  --background: #5e9a39;
}
.netz-toast-warn {
  --color: #ffffff;
  --background: #ffc409;
}

ion-modal#score-modal {
  --width: fit-content;
  --min-width: 50%;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}
ngx-table,
.ngx-table {
  color: inherit !important;
  border-collapse: collapse !important;
  border-spacing: 0 !important;
  font-family: inherit !important;
  font-size: inherit !important;
  text-align: center !important;
  // display: table !important;
  width: auto !important;
  height: auto !important;
  .ngx-container {
    margin-left: auto !important;
    margin-right: auto !important;
    overflow: auto;
  }
  tr {
    font-size: 16px !important;
    border-bottom: 0.5px #d9d9d9 solid !important;
    td {
      // padding: unset !important;
      padding: 6px !important;
    }
  }
  .ngx-table__header-cell {
    color: gray;
  }
  .ngx-table__header-title {
    text-wrap: nowrap;
  }

  .ngx-table__header {
    border-bottom: 1px solid gray !important;
    font-size: 14px !important;
  }
  // .ngx-table__table--normal > tbody > tr > td {
  //   padding: 24px 24px !important;
  //   font-size: 18px !important;
  // }
}
#paginationtable {
  .ngx-pagination {
    font-family: inherit !important;
    .current {
      padding: 0.1875rem 0.625rem !important;
      background: #079ade !important;
      color: #fefefe !important;
      cursor: default !important;
    }
  }

  .ngx-pagination li {
    display: inline-block !important;
    border-radius: 6px !important;
    font-size: 20px !important;
    font-family: inherit !important;
    margin: 0 4px !important;
  }
  .ngx-pagination .small-screen {
    border: unset !important;
    margin: 0 1rem !important;
  }
}

.validation-error {
  color: red;
  margin: 5px 0;
  font-size: 0.9rem;
}
ion-textarea.custom {
  --background: grey;
}

ion-modal.logout-modal-container {
  --width: 350px;
  --height: 280px;
}
